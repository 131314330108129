import React, { useEffect, useCallback } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../../content';
import { CURRENCY } from '../../content/currency';
import { getAccessToken } from '../helpers';
import { MemberPopContext } from '../MemberPop';

const Credits = React.memo(() => {

    const uid = useSelector((state) => state.user.uid)
    const creditsPackages = useSelector((state) => state.auctions.creditsPackages)

    const { step, bidPackage, paymentIntent, setPaymentIntent } = useContext(MemberPopContext)

    useEffect(() => {

        if (uid && !paymentIntent) {

            // CREATE PAYMENT INTENT
            getAccessToken().then(token => {

                return fetch(`${process.env.REACT_APP_URL_CREATE_PAYMENT_INTENT}?bidPackage=${bidPackage}&uid=${uid}&accessToken=${token}`)

            })
                .then(response => response.json())
                .then((resp) => {

                    if (resp.paymentIntent && resp.paymentIntentId) {

                        setPaymentIntent({
                            intent: resp.paymentIntent,
                            id: resp.paymentIntentId
                        })

                    } else {
                        console.error('no payment intent id')
                    }

                })

        }

    }, [uid, bidPackage, paymentIntent, setPaymentIntent]);

    return (
        <div className={`creditsBox column ${(step === 'creditCardListing') || (step === 'addCreditCard') ? 'hide' : ''}`}>
            {creditsPackages?.map((x, i) => <CreditBox key={x.amount} {...x} count={i} />)}
        </div>
    );
})

const CreditBox = React.memo(({ amount, cost, count }) => {

    const uid = useSelector((state) => state.user.uid)

    const { bidPackage, setBidPackage, paymentIntentId } = useContext(MemberPopContext)

    const updatePaymentIntent = useCallback(() => {

        setBidPackage(count)

        fetch(`${process.env.REACT_APP_URL_UPDATE_PAYMENT_INTENT}?bidPackage=${count}&uid=${uid}&paymentIntentId=${paymentIntentId}`)
            .then(response => response.text())
            .then((resp) => {
                if (resp !== 'updated') {

                }
            })

    }, [uid, count, paymentIntentId, setBidPackage]);

    return (
        <div className={`credit row ${count === bidPackage ? 'selected' : ''}`} onClick={updatePaymentIntent}>

            <div className="left row">

                <div className='radio row'>
                    <div className="circle"></div>
                </div>

                <div className="amount row">
                    <span>{amount}</span>
                    <div className="sub">{DEFAULT_TEXT['member.pop.bidSelect.bids']}</div>
                </div>

            </div>

            <div className="cost">
                {CURRENCY[process.env.REACT_APP_ISO_CODE].dollar}{cost}
            </div>

        </div>
    );
})

export default Credits;