export const helpTitles = {

    bidding: 'Bidding',
    productsAndShipping: 'Products and Shipping',
    default: 'Other Info',
    contact: 'Contact Us'

}

export const helpTopics = [{
    title: `How it works`,
    html: `
    <p>Winning on auctions is easy. Simply be the last bidder before the timer hits 00:00:00 and you will be declared the winner of the auction.</p>
    <p><strong>Here's how to win:</strong></p>

    <ol>
        <li>Before you can take part in an auction you need to buy bids. Bids are currently on sale for as low as 40¢ each. (Price per bid depends on how many bids you purchase)</li>
        <li>Each bid raises the price by $0.01.</li>
        <li>The auctions clock restarts from 10 seconds for each bid placed under 10 seconds. (This gives each bidder a chance to place their final bids)</li>
        <li>If no new bids are placed before the clock runs out, the last bidder wins.</li>
        <li>The last bidder pays the final auction price and we ship them their winning item.</li>
    </ol>
    `,
    group: 'bidding'
}, {
    title: `How to bid`,
    html: `
    <ol>
        <li>First find products you like. We have countless brand name products to bid on.</li>
        <li>Use the "Bid" button on your desired auction(s).</li>
        <li>Each time you bid you will become the high bidder.</li>
        <li>One single bid will be deducted from your account with each bid placed.</li>
        <li>Be the last bidder before the countdown hits 00:00:00 and you win!</li>
    </ol>
    `,
    group: 'bidding'
}, {
    title: `
        How much do bids cost?
    `,
    html: `
        <p><strong>Bids come in INSERT_QUANTITIES main quantities:</strong></p>
    `,
    group: 'bidding',
    creditsPackages: true,
}, {
    title: `How many bids can I purchase?`,
    html: `
        <p>There is no limit to the amount of bids you're able to purchase. Add more bids to your account at any time with no restrictions.</p>
    `,
    group: 'bidding'
}, {
    title: `What is the price for my winning auction?`,
    html: `
        <p>You only pay your winning auction price as an additional cost to any of the bids that you have already placed inside the auction. Shipping is absolutely free.</p>
    `,
    group: 'bidding'
}, {
    title: `Which countries can I place bids from?`,
    html: `
        <p>You must be a resident of  the USA, and have a residential address inside of the USA to place bids and receive products.</p>
    `,
    group: 'bidding'
}, {
    title: `Can employees bid on auctions?`,
    html: `
        <p>Employees and their family members (defined as parents, spouse, siblings and children) are strictly forbidden from partipating, bidding and winning on all auctions.</p>
    `,
    group: 'bidding'
}, {
    title: `Are all products brand new?`,
    html: `
        <p>Yes! All products are brand new and factory sealed.</p>
    `,
    group: 'productsAndShipping'
}, {
    title: `How much does shipping cost?`,
    html: `
        <p>Shipping is absolute free of charge on all auctions.</p>
    `,
    group: 'productsAndShipping'
}, {
    title: `How long will it take to receive my winning item?`,
    html: `
        <p>We express ship your item within 24 hours of you completing payment on your winning auction. Once your item has been shipped we email you a tracking number. You will usually receive your product within 2-3 business days after it has been shipped.</p>
    `,
    group: 'productsAndShipping'
}, {
    title: `Will I receive a tracking number when my item is shipped?`,
    html: `
        <p>Yes, we will email you a  tracking number to confirm your shipment.</p>
    `,
    group: 'productsAndShipping'
}, {
    title: `Returns`,
    html: `
        <p>If you wish to return your item please contact support within 7 days of receiving it. If your item is defective, damaged or does not match the product description. We will replace it for your at no additional cost.</p>
    `,
    group: 'productsAndShipping'
}, {
    title: `Does it cost anything to become a member?`,
    html: `
        <p>Absolutely not. Becoming a member is completely free. There is no hidden costs or membership fees.</p>
    `
}, {
    title: `Which payment methods do you accept?`,
    html: `
        <p>We accept all major credit cards brands including: Visa, Mastercard, American Express, Diners, JCB and Discover</p>
    `
}, {
    title: `Account limits`,
    html: `
        <p>There is a maximum of 1 account allowed per household. Any additional accounts located will be instantly closed.</p>
    `
}, {
    title: `Team play`,
    html: `
        <p>To keep auctions fair all team play is strictly forbidden. No team bidding on auctions is allowed with friends, family members or acquaintances. We use advanced tracking software to track all patterns of team play. All accounts conducting in any form of team play will be instantly removed and restricted from future bidding.</p>

    `
}, {
    title: `Contact the support team`,
    html: `
        <p>Have a question, comment or concern? Enter your information below. One of our knowledgable support agents would glady reply to you as soon as possible.</p>
    `,
    group: 'contact',
    contactForm: true
}
]