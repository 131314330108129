export const ACCEPTED_LANGUAGES = {
    'EN': 'en'
}

export const DEFAULT_TEXT = {

    'header.tab.deals': 'Deals',

    'form.email.placeholder': 'Email',
    'form.email.error.required': 'Please enter your email',
    'form.email.error.valid': 'Please enter a valid email',
    'form.password.placeholder': 'Password',
    'form.password.signup.error.required': 'Please create a password',
    'form.password.signin.error.required': 'Please enter your password',
    'form.password.resetPassword.error.required': 'Please create a new password',
    'form.password.error.minimum': 'Password must be a minimum of 6 characters in length',
    'form.displayName.placeholder': 'Display name',
    'form.displayName.error.required': 'Please enter a display name',
    'form.displayName.error.minimum': 'Display name must be a minimum 3 characters in length',
    'form.displayName.error.base': `Whoops! Something went wrong, please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a> to update your display name.`,
    'form.baseError.inUse': 'Email is already in use. Please sign in.',
    'form.baseError.forgotPassword': "We couldn't find an account with that email address",
    'form.baseError.resetPassword': `Your reset password link has expired. <a href="{replaceUrl}"><u>Please try again.</a>`,
    'form.baseError.userNotFound': `Whoops. We could not find your account. Please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a>.`,
    'form.baseError.whoops.signup': 'Whoops. Something went wrong, please try again.',
    'form.baseError.whoops.signin': 'Incorrect email or password.',

    'form.success.forgotPassword': "A confirmation link has been sent to your email.",
    'form.success.resetPassword': 'Password saved. Signing in....',

    'page.auction.checks': [
        `The item is brand new, unused, unopened, undamaged, and in its original packaging (where packaging is applicable).`,
        `Delivery is free, with no minimum order threshold. Receive your order in 2 business days or less.`,
        `Bid, win and save if you're located anywhere in the US.`,
        `Each bid placed increases the price of the auction by $0.01.`,
        `Bids are non refundable once placed.`,
        `Be the last bidder before the countdown strikes 00:00:00 and be the winner.`,
        `Pay only the final price. (Plus the cost of each bid you placed)`],
    'page.sold.title': `Recent selling auctions`,
    'page.sold.blurb': `Save Up to 95% off retail price!`,


    'shoppingFrom.preText': `You're Shopping from`,
    'shoppingFrom.underline': 'Set Your Location',
    'shoppingFrom.success': `Your location is set!`,
    'shoppingFrom.title': `Update Your Location`,
    'shoppingFrom.placeholder': 'Zip code',
    'shoppingFrom.error': 'Please enter a valid zip code.',
    'shoppingFrom.button': 'Set Location',

    'member.pop.title.addCredits': `Add Bids`,
    'member.pop.title.creditCard': `Credit Card`,
    'member.pop.title.avatars': `Select Your Avatar`,
    'member.pop.title.bidsAdded': `Bids Added!`,
    'member.pop.title.editDisplayName': `Edit display name`,
    'member.pop.title.editDisplayName': `Edit display name`,
    'member.pop.title.creditCardListing': `Select Credit Card`,
    'member.pop.title.cartModeShipping': `Shipping Address`,
    'member.pop.title.cartBilling': `Credit Card`,
    'member.pop.title.idleBox': `Are you still there?`,
    'member.pop.button.addCredits': 'Next',
    'member.pop.button.creditCard': 'Start Bidding!',
    'member.pop.button.avatars': 'Continue',
    'member.pop.button.bidsAdded': 'Continue',
    'member.pop.button.editDisplayName': 'Save',
    'member.pop.button.addCards': 'Add New Card',
    'member.pop.button.submit': 'Submit',
    'member.pop.button.cartModeShipping': 'Continue',
    'member.pop.button.cartBilling': 'Order',
    'member.pop.button.idleBox': 'Yes! Go Back.',
    'member.pop.bidSelect.bids': 'Bids',
    'member.pop.afterPurchase.bids': 'Bids Added',
    'member.pop.selectCreditCard.addNew': 'Add a new credit card',
    'member.pop.selectCreditCard.addNewError': `There was an error with your card. Please use a different payment method or try again later.`,
    'member.pop.editDisplayName.placeholder': 'New Display Name',


    'page.myaccount.shippingInfo.errors.firstName': `Please enter your first name`,
    'page.myaccount.shippingInfo.errors.lastName': `Please enter your last name`,
    'page.myaccount.shippingInfo.errors.phoneNumber': `Please enter your phone number`,
    'page.myaccount.shippingInfo.errors.address': `Please enter your address`,
    'page.myaccount.shippingInfo.errors.city': `Please enter your city`,
    'page.myaccount.shippingInfo.errors.state': `Please enter your state`,
    'page.myaccount.shippingInfo.errors.zipCode': `Please enter your zip code`,
    'page.myaccount.shippingInfo.errors.base': `Whoops! Something went wrong, please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a> to update your address.`,

    'page.myaccount.shippingInfo.firstName.placeholder': 'First Name',
    'page.myaccount.shippingInfo.lastName.placeholder': 'Last Name',
    'page.myaccount.shippingInfo.phoneNumber.placeholder': 'Phone Number',
    'page.myaccount.shippingInfo.address.placeholder': 'Address',
    'page.myaccount.shippingInfo.address2.placeholder': `Apt, suite, etc (optional)`,
    'page.myaccount.shippingInfo.city.placeholder': 'City',
    'page.myaccount.shippingInfo.state.placeholder': 'State',
    'page.myaccount.shippingInfo.zipCode.placeholder': 'Zip Code',

    'page.signin.title': 'Welcome back!',
    'page.signup.title': `Let's get started!`,
    'page.forgotPassword.title': 'Forgot Your Password?',
    'page.resetPassword.title': `Reset Your Password`,

    'page.signin.subtitle': `Sign into your account now.`,
    'page.signup.subtitle': `Sign up for free. Start bidding!`,
    'page.forgotPassword.subtitle': `A password reset link will be sent to your email.`,
    'page.resetPassword.subtitle': `Securely reset your password now.`,

    'page.signin.under.button.text': `Need to create an account?`,
    'page.signup.under.button.text': `Already a member?`,
    'page.forgotPassword.under.button.text': `Need to create an account?`,
    'page.resetPassword.under.button.text': `Need to create an account?`,

    'page.signup.icon.0': 'Top brand name items',
    'page.signup.icon.1': 'Free 2-Day shipping',
    'page.signup.icon.2': 'No membership costs',
    'page.signup.icon.3': 'No hidden fees',
    'page.signup.icon.4': 'All items brand new and unopened',
    'page.signup.icon.5': 'Up to 95% off retail price!',

    'page.myaccount.tab.profile': `Profile`,
    'page.myaccount.tab.shippingInfo': `Shipping info`,
    'page.myaccount.tab.winningAuctions': `Winning auctions`,
    'page.myaccount.tab.orderHistory': `Order history`,
    'page.myaccount.tab.bidHistory': `Bid history`,
    'page.myaccount.tab.paymentMethods': `Payment Methods`,
    'page.myaccount.tab.account': `Account`,

    'page.myaccount.tab.account.resetPassword': 'Reset your password',
    'page.myaccount.tab.account.pleaseCheckYourEmail': 'Please check your email for a reset password link.',

    'page.myaccount.tab.bidHistory.bidPrice': 'Bid Price:',
    'page.myaccount.tab.bidHistory.noHistory': `You have not placed any bids yet.`,

    'page.myaccount.tab.paymentMethods.noSaved': 'You do not have any saved payment methods.',
    'page.myaccount.tab.paymentMethods.label.zipCode': 'Zip Code:',
    'page.myaccount.tab.paymentMethods.label.expiry': 'Expiry:',

    'page.myaccount.tab.paymentMethods.error.base': `Whoops! Something went wrong. Please contact <a href="mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}"><u>support</u></a>.`,
    'page.myaccount.tab.paymentMethods.error.zipCode': 'Zip code must be 5 numbers.',

    'page.myaccount.tab.orderHistory.noHistory': 'You currently have no orders.',

    'button.myaccount.tab.profile.avatar': 'Avatar',
    'button.myaccount.tab.profile.displayName': 'Display Name',
    'button.myaccount.tab.profile.email': 'Email',
    'button.myaccount.tab.profile.bidsAvailable': 'Bids Available',

    'page.help.title': 'Help Topics',
    'page.help.intro': `Welcome to our guides! Get answers to common topics including: bidding, winning, shipping and more. Can't find the answer you're looking for? No problem. You can contact support with your question at any time and we would gladly assist you.`,
    'page.help.nextTopic': 'Next topic:',

    'page.help.contact.email.placeholder': 'Your email',
    'page.help.contact.message.placeholder': 'Your email',

    'page.help.contact.error.email': `Please enter a valid email address`,
    'page.help.contact.error.message': `Please enter your message`,

    'page.help.contact.success': 'Thank you for your inquiry. Your message has been successfully received. A support agent will view your request and respond to you shortly.',

    'auction.sold': 'SOLD',
    'auction.noBidders': `No Bidders Yet`,
    'auction.highBidder': `You're already the high bidder`,
    'auction.biddingHistory': `Bidding History`,
    'auction.endingSoon': `Auctions Ending Soon:`,
    'auction.countdown.ended': 'ENDED',

    'auction.extraDetail.1': `Condition:`,
    'auction.extraDetail.1.text': `Brand New`,
    'auction.extraDetail.2': `Delivery to:`,
    'auction.extraDetail.2.text': `United States`,
    'auction.extraDetail.3': `Shipping:`,
    'auction.extraDetail.3.text': `2 Business Days (Free)`,
    'auction.extraDetail.4': `Payments:`,

    'auction.details.title': `Description of`,
    'auction.details.title.2': `Auction Details:`,
    'auction.details.title.3.bottom': `Don't miss out! Start bidding on auctions right now!`,

    'auction.notFound.title': 'Whoops!',
    'auction.notFound.sub': 'Auction has expired or does not exist. <br>Please select a new auction.',
    'auction.notFound.button': 'View Auctions',

    'url.signup': '/signup',
    'url.signin': '/signin',
    'url.forgotPassword': '/forgot-password',
    'url.resetPassword': '/reset-password',
    'url.sold': '/sold',
    'url.howItWorks': '/how-it-works',
    'url.help': '/help',

    'url.signin.text': 'Sign In',
    'url.signup.text': 'Join',
    'url.forgotPassword.text': 'Forgot Your Password?',
    'url.sold.text': 'Sold',
    'url.howItWorks.text': 'How it Works',
    'url.help.text': 'Help',

    'url.terms': '/terms',
    'url.privacy': '/privacy',

    'button.signin': 'Sign In',
    'button.signup': 'Sign Up',
    'button.signup.bottom': 'Create your account now!',
    'button.join': 'Join',
    'button.resetPassword': 'Reset Password',
    'button.forgotPassword': 'Reset Password',

    'button.dealTabHeader': 'View Deals',

    'button.contact.submit': 'Submit',

    'button.myaccount.tab.account.sendReset': 'Send password reset email',

    'button.myaccount.tab.paymentMethods.addPaymentMethod': 'Add Payment Method',
    'button.myaccount.tab.paymentMethods.updateCard': 'Update Card',
    'button.myaccount.tab.paymentMethods.remove': 'Remove',
    'button.myaccount.tab.paymentMethods.close': 'Close',
    'button.myaccount.tab.paymentMethods.addNew': 'Add new payment method',

    'button.myaccount.tab.shippingInfo.save': 'Save',
    'button.myaccount.tab.shippingInfo.success': 'Address Saved',

    'button.myaccount.tab.profile.edit': 'Edit',
    'button.myaccount.tab.profile.addBids': 'Add Bids',

    'button.myaccount.tab.winningAuctions.noHistory': 'You currently have no winning auctions.',

    'button.bid': 'BID',
    'button.bid.carMode': 'BUY',

    'footer.title.deals': 'Deals',
    'footer.title.help': 'Help',
    'footer.language.english': 'English',
    'footer.flag.usa': 'usa',
    'footer.phoneText': 'Phone:',

    'terms.text': 'Terms of Use',
    'privacy.text': 'Privay Policy',

    'breadcrumb.allDeals': 'All Deals',
    'breadcrumb.and': 'and',
    'breadcrumb.sold': 'Sold',
    'breadcrumb.results': 'Results',
    'breadcrumb.page': 'Page:'

}

//REPLACE URLS
DEFAULT_TEXT['form.baseError.resetPassword'] = DEFAULT_TEXT['form.baseError.resetPassword'].replace('{replaceUrl}', DEFAULT_TEXT['url.forgotPassword'])

export const DISPLAY_NAME_MAX_CHARACTERS = 20;

