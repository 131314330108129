import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FooterBox } from '../style/main';
import Logo from './snippet/Logo';
import { createHelpGroups } from './helpers';
import { useSelector } from 'react-redux';
import { DEFAULT_TEXT } from '../content';
import { Link } from 'react-router-dom';
import { useCartMode } from '../providers/CartMode';
import { BRAND_ALL_TEXT, BRAND_LIST } from '../content/brands';

const topics = createHelpGroups();

let helpTopics = []

for (const key in topics) {

    const title = topics[key].title;

    const obj = {
        param: key,
        title
    }

    helpTopics.push(obj)

}

const FooterDealTab = React.memo(({ BRAND }) => {

    const navigate = useNavigate();

    const allText = BRAND_ALL_TEXT[process.env.REACT_APP_ISO_CODE];
    let allTab = BRAND === allText.toUpperCase();

    let name = allTab ? allText : BRAND_LIST[BRAND]

    if (!name) {
        return null
    }

    const viewDeals = useCallback(() => {

        window.scrollTo(0, 0)

        navigate({
            pathname: '/',
            search: allTab ? '' : `?filter=${BRAND}`
        });



    }, [BRAND, allTab]);


    return (
        <li onClick={viewDeals}>
            {name}
        </li>
    );
})


const HelpLi = React.memo(({ title, helpPage, param }) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const onClickHelp = useCallback((e) => {

        if (helpPage) {
            // SCROLL TO SECTION IF YOU CLICK THE SAME OPEN MENU ON HELP PAGE
            const section = searchParams.get("section");
            const id = document.getElementById(param)

            if ((section === param) && id) {

                e.preventDefault();

                const top = id.offsetTop - 10;

                window.scrollTo({
                    top,
                    left: 0
                });
            }

        }

    }, [helpPage, searchParams]);



    return (

        <li>

            <Link to={`/help?section=${param}`} onClick={onClickHelp}>
                {title}
            </Link>

        </li>

    );
})


const Footer = React.memo(({ auctionPage, soldPage, helpPage }) => {

    const { cartMode } = useCartMode();

    const uid = useSelector((state) => state.user.uid)
    const userLoaded = useSelector((state) => state.user.userLoaded)
    const brands = useSelector((state) => state.auctions.brands)

    return (
        <FooterBox className={`${auctionPage || soldPage ? 'mobilePadding' : ''} row`}>

            <div className='wrap column'>

                <div className="menu row">

                    <div className="menuBlock column">
                        <h3>{DEFAULT_TEXT['footer.title.deals']}</h3>

                        <ul className="column">

                            {brands.map(({ brand }, i) => <FooterDealTab key={i} BRAND={brand} helpPage={helpPage} />)}


                        </ul>

                    </div>

                    <div className="menuBlock column">
                        <h3>{DEFAULT_TEXT['footer.title.help']}</h3>

                        <ul className="column">

                            {!cartMode &&

                                helpTopics.map((x, i) => <HelpLi key={i} {...x} helpPage={helpPage} />)

                            }

                            {cartMode && <li>{DEFAULT_TEXT['footer.phoneText']} <a href={`tel:${process.env.REACT_APP_URL_PHONE_NUMBER}`}>{process.env.REACT_APP_URL_PHONE_NUMBER}</a></li>}
                            <li className="lowercase"><a href={`mailto:${process.env.REACT_APP_URL_SUPPORT_EMAIL}`}>{process.env.REACT_APP_URL_SUPPORT_EMAIL}</a></li>

                        </ul>

                    </div>

                    <div className="menuBlock column">

                        <div className="flagBox row">
                            <img src={`/img/flags/${DEFAULT_TEXT['footer.flag.usa']}.svg`} className='flag' />
                            <span>{DEFAULT_TEXT['footer.language.english']}</span>
                        </div>

                        {(!uid && userLoaded && !cartMode) &&
                            <div className="smallBtns row">

                                <Link to={DEFAULT_TEXT['url.signin']} className='smallBtn'>
                                    {DEFAULT_TEXT['button.signin']}
                                </Link>

                                <Link to={DEFAULT_TEXT['url.signup']} className='smallBtn'>
                                    {DEFAULT_TEXT['button.join']}
                                </Link>

                            </div>
                        }

                        {cartMode && <img src="/img/ad.png" className="address" />}

                        <div className={`copyright ${uid ? 'signedIn' : ''}`}>

                            &copy; 2022 {process.env.REACT_APP_URL_SITE_NAME}.com

                        </div>

                    </div>

                </div>

                <div className="footerBottom row">

                    <Logo />

                    <div className="footerBottomRight row">

                        <Link to={DEFAULT_TEXT['url.terms']}>
                            {DEFAULT_TEXT['terms.text']}
                        </Link>

                        <Link to={DEFAULT_TEXT['url.privacy']}>
                            {DEFAULT_TEXT['privacy.text']}
                        </Link>

                    </div>

                </div>

            </div>

        </FooterBox>
    );
})

export default Footer;