import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import DealTab from './dealsTab/DealTab';
import { Link } from 'react-router-dom';

const MenuTab = React.memo((props) => {

    const { signedIn, mobileOnly, dealsTab, name, url } = props;

    const uid = useSelector((state) => state.user.uid)

    if (uid && signedIn) {
        return null
    }

    return (
        <li className={dealsTab ? 'dealsTab' : mobileOnly ? 'mobile' : ''}>
            {dealsTab ? <DealTab {...props} />
                :
                <Link className="liInner" to={url}>
                    <div>{name}</div>
                </Link>
            }
        </li>
    );
})

export default MenuTab;